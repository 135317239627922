
import { defineComponent } from 'vue'
import { OrderActions } from '@/store/modules/orders/actions'
import { OrderMutations } from '@/store/modules/orders/mutations'
import { useToast } from 'vue-toastification'
import { required, minLength } from '@/customValidators'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  name: 'OrderTracker',
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      orderKey: '',
      status: 1,
      loading: false,
      statuses: [
        { status: 'preparing', value: 1, translation: 'orders.statuses.preparing' },
        { status: 'picked_up', value: 2, translation: 'orders.statuses.picked_up' },
        { status: 'on_the_way', value: 3, translation: 'orders.statuses.on_the_way' },
        { status: 'delivered', value: 4, translation: 'orders.statuses.delivered' }
      ]
    }
  },
  validations: function () {
    return {
      orderKey: { required, minLength: minLength(4) } // { required, minLength: minLength(8) }
    }
  },
  computed: {
    detailedOrder () {
      return this.$store.getters['orders/getDetailedOrder']
    }
  },
  created () {
    // Access the order ID from the query parameters when the component is created
    if (this.$route.query.id) {
      this.orderKey = this.$route.query.id?.toString()
    }
  },
  methods: {
    async trackOrder () {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      const orderId = this.orderKey
      this.loading = true
      try {
        await this.$store.dispatch(`orders/${OrderActions.PUBLIC_FETCH_ORDER_BY_ID}`, { orderId })
        switch (this.detailedOrder.orderStatus.name) {
          case 'preparing':
          case 'ready_for_pick_up':
          case 'assigned':
            this.status = 1
            break
          case 'picked_up':
            this.status = 2
            break
          case 'on_the_way':
          case 'at_the_address':
          case 'delayed':
            this.status = 3
            break
          default:
            this.status = 4
            break
        }
      } catch (error:any) {
        const toast = useToast()
        toast.error(error.message || 'Failed to fetch order details.') // Show error message
      } finally {
        this.loading = false
      }
    },
    trackAnotherOrder () {
      this.$store.commit(`orders/${OrderMutations.SET_ORDER_DETAILS}`, null)
      this.orderKey = ''
    }
  },
  reset () {
    this.v$.$reset()
    this.orderKey = ''
  }
})
